import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  errors: {
    paddingTop: 6,
    color: '#f44336',
    '& > p': {
      fontSize: '1.0714285714285714rem',
      lineHeight: 1.66,
    },
  },
}));

export const FormFieldErrorUI = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.errors}>
      <Typography>
        {children}
      </Typography>
    </Box>
  );
};
