import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { navigate } from 'gatsby';
import { useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import { HospitalForm } from './HospitalForm';
import { GET_HOSPITALS } from '../../../graphql/queries';
import { CREATE_HOSPITAL_WITH_LOGO } from '../../../graphql/mutations';
import { Navbar } from '../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../componentsUI/SectionBar';
import { ScrollableContainer } from '../../Common/ScrollableContainer';
import useStyles from '../styled/HospitalFormMakeStyles';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';


export const HospitalNew = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [lockSubmit, setLockSubmit] = useState(false);

  const [createHospital, { loading }] = useMutation(CREATE_HOSPITAL_WITH_LOGO, {
    // eslint-disable-next-line no-shadow
    onCompleted: async ({ createHospital }) => {
      toast(`${t('hospital.created.successfully')}...`, { className: 'toast-info' });
      dispatch({ type: 'CHANGE_HOSPITAL', current: createHospital });
      setTimeout(() => navigate('/'), 3500);
    },
    onError: (e) => {
      setLockSubmit(false);
      if (e && e.graphQLErrors && e.graphQLErrors.length) {
        setServerError(e.graphQLErrors[0].message);
      }
    },
    refetchQueries: [{ query: GET_HOSPITALS }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    setLockSubmit(true);
    try {
      const hospitalInput = {
        name: values.name,
        streetAddress: values.streetAddress,
        postalCode: values.postalCode,
        city: values.city,
        country: values.country.toUpperCase(),
        mainColor: values.mainColor,
      };
      if (file) hospitalInput.logo = file;
      await createHospital({ variables: { hospitalInput } });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (Object.keys(formikErrors).length === 0 && formikErrors.constructor === Object) {
        setServerError(networkErrorParse(errors));
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="new.hospital" />
      </Navbar>

      <Box className={classes.container}>
        <ScrollableContainer padding="1.25em 0 0">
          <Paper elevation={2} className={classes.wrapper}>
            <HospitalForm
              submitLabel={t('create')}
              handleSubmit={handleSubmit}
              serverError={serverError}
              setFile={setFile}
              submitting={loading}
              lockSubmit={lockSubmit}
            />
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
